import Layout from "../../components/layout";
import PageTitle from "../../components/pagetitle";
import Seo from "../../components/seo";
import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";

const AnnapolisPage = () => (
    <Layout>
        <PageTitle title="Annapolis, MD" page="PageTitleAbout" />
        <Seo title="Managed Services and Software In Annapolis, MD"
             description="New Vertical offers Managed Services and Software services to The city of Annapolis, Maryland is home to a wide range of innovative and cutting-edge technology services."
             pathname={"/locations/annapolis/"}/>
        <div className="content-page-wrapper">
            <div className="page-intro">
                <StaticImage
                    style={{
                        gridArea: "1/1",
                    }}
                    layout="fullWidth"
                    loading="eager"
                    aspectRatio={3 / 1}
                    alt="Our team will help"
                    // Original Photo by Austin Distel Unsplash
                    src={
                        "../../images/Annapolis.jpg"
                    }
                    formats={["auto", "jpg", "avif"]}
                />
                <h2>Managed Services and Software In Annapolis, MD</h2>

                <p>The city of Annapolis, Maryland is home to a wide range of innovative and cutting-edge technology services. Located in the heart of the Chesapeake Bay region, the city is known for its commitment to providing residents and businesses an innovative environment to support technology projects of all sizes. From web development and graphic design to data analysis and cloud computing, Annapolis is one of the most tech-savvy cities in the nation.</p>
                <p> One of the most popular technology services in Annapolis is web development. Companies such as Digital Media Group, Innovative Web Solutions, and New Vertical Technologies offer comprehensive web design, development, and managed technology services. These companies specialize in creating custom websites, ecommerce stores, and mobile applications. They also provide hosting, domain name registration, and search engine optimization services.</p>
                <p> In addition to web design and development, Annapolis is home to several software development companies. Companies such as E-Soft Solutions, Inc., Cybernet Solutions, and Infotech Solutions provide software and application development services to local businesses. These companies specialize in developing custom software solutions, enterprise resource planning systems, and mobile applications. They can also provide IT consulting services and help businesses to choose the best technology for their particular needs.</p>
                <p>Data analysis is another area where Annapolis technology companies excel.</p>
            </div>
        </div>
    </Layout>
)

export default AnnapolisPage